//** landing

@mixin wrapper() {
  box-sizing: border-box;
  padding: 0px 0 0 46px;
  color: white;
  h2{
    margin: 25px 0;
    font-family: $font-primary;
    font-size: 34px;
    text-transform: uppercase;
  }
  p{
    font-size: 18px;
    line-height: 1.2;
  }
  a{
    color: white;
    font-weight: 900;
  }
  @media (max-width: $mqPhone) {
    padding: 0px 10px 0 10px;
  }
}

body{
  padding: 0px;
  margin: 0px;
  font-family: $font-base;
  @media screen and (max-width: $mqTablet) {
    background: #58b5b1;
  }
}

.head{
  //border: 1px solid red;
  @media (max-width: $mqPhone) {
    position: relative;
    //background: white;
    padding-bottom: 30px;
  }
}

//** logo
.logo{
  display: block;
  background-image: url("img/rit.png");
  background-position: center center;
  background-repeat: no-repeat;
  width: 172px;
  height: 67px;
  position: absolute;
  top: 28px;
  left: 30px;
  @media (max-width: $mqPhone) {
    position: relative;
    top: 20px;
    left: 0;
    margin: 0px 10px 30px;
  }
}

.bcs{
  display: block;
  background-image: url("img/bcs.png");
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  left: 60px;
  bottom: 32px;
  width: 66px;
  height: 81px;
  @media (max-width: $mqTablet) {
    left: 36px;
  }
  @media (max-width: $mqPhone) {
    position: relative;
    left: 0px;
    bottom: 0px;
    margin: 10px;
  }
}

.f-label{
  font-family: $font-primary;
  font-size: 45px;
  position: absolute;
  right: 125px;
  bottom: 20px;
  line-height: 1;
  color: white;
  opacity: 0.3;
  text-decoration: none;
  span{
    font-size: 63px;
    line-height: 1;
  }
  @media (max-width: $mqPhone) {
    position: relative;
    right: auto;
    bottom: auto;
    margin: 0 0px;
    padding: 10px;
  }
}

h2{
  margin: 0 0;
  font-family: $font-primary;
  font-size: 24px;
}

.section{
  box-sizing: border-box;
  padding: 20px;
  background-image: url("img/landing-page.jpg");
  background-position: top left;
  background-repeat: no-repeat;
  //background-size: cover;
  width: 1280px;
  max-width: 100%;
  //min-height: 1557px;
  margin: 0px auto;
  position: relative;
  padding-bottom: 130px;
  @media screen and (max-width: $mqTablet) {
    padding: 0px 0 120px;
  }
  @media (max-width: $mqPhone) {
    //background: none;
    padding: 0px 0 0px;
  }
}

.welcome{
  padding: 32px 0 0 296px;
  box-sizing: border-box;
  > h1{
    //max-width: 358px;
    display: block;
    margin: 0 0;
    font-family: $font-primary;
    font-weight: normal;
    font-size: 81px;
    color: #cb0055;
    line-height: 1;
    text-transform: uppercase;
    > small {
      display: block;
      font-size: 46px;
      line-height: 1;
      position: relative;
      top: -10px;
    }
  }
  @media (max-width: $mqTablet) {
    padding: 72px 0 0 296px;
    > h1{
      font-size: 61px;
      line-height: 1;
      > small {
        font-size: 26px;
        line-height: 1;
        top: 0px;
      }
    }
  }
  @media (max-width: $mqPhone) {
    padding: 0px 0 0 10px;
    > h1{
      font-size: 61px;
      line-height: 1;
      > small {
        font-size: 26px;
        line-height: 1;
        top: 0px;
      }
    }
  }
}

.apply{
  display: block;
  box-sizing: border-box;
  width: 222px;
  height: 222px;
  background-color: #ffb909;
  color: white;
  border-radius: 100%;
  font-family: $font-primary;
  font-weight: normal;
  font-size: 40px;
  text-decoration: none;
  padding-top: 80px;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: -4px;
  position: absolute;
  top: 15.8%;
  right: 25.5%;
  //top: 248px;
  //right: 328px;
  span{
    font-size: 60px;
  }
  @media (max-width: $mqDesktop) {
    top: 22%;
    right: auto;
    left: 430px;
  }
  @media (max-width: $mqTablet) {
    top: 18%;
    //right: 30px;
  }
  @media (max-width: $mqPhone) {
    position: relative;
    top: 0;
    right: 0;
    left: 10px;
    margin-top: 24px;
  }
}

.why{
  @include wrapper();
  padding-top: 22px;
  max-width: 350px;
  h2{
    max-width: 200px;
  }
  @media (max-width: $mqTablet) {
    padding-top: 84px;
  }
  @media (max-width: $mqPhone) {
    padding-top: 0px;
  }
}

.how{
  @include wrapper();
  max-width: 700px;
}

.peoples{
  max-width: 800px;
  @include wrapper();
  .people{
    display: inline-block;
    overflow: hidden;
    width: 122px;
    height: 122px;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    margin-right: 25px;
    img{
      opacity: 0;
    }
    &:before{
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-image: url("img/play.png");
      background-position: center center;
      background-repeat: no-repeat;
    }
    @media (max-width: $mqPhone) {
      width: 72px;
      height: 72px;
      margin-right: 10px;
    }
  }
}

.what{
  @include wrapper();
  max-width: 800px;
}