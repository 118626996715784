//
// Variables
// --------------------------------------------------

@font-face {
  font-family: 'oxygenregular';
  src: url('fonts/Oxygen/oxygen-regular-webfont.eot');
  src: url('fonts/Oxygen/oxygen-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/Oxygen/oxygen-regular-webfont.woff2') format('woff2'),
       url('fonts/Oxygen/oxygen-regular-webfont.woff') format('woff'),
       url('fonts/Oxygen/oxygen-regular-webfont.ttf') format('truetype'),
       url('fonts/Oxygen/oxygen-regular-webfont.svg#oxygenregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'oxygenbold';
    src: url('fonts/Oxygen/oxygen-bold-webfont.eot');
    src: url('fonts/Oxygen/oxygen-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Oxygen/oxygen-bold-webfont.woff2') format('woff2'),
         url('fonts/Oxygen/oxygen-bold-webfont.woff') format('woff'),
         url('fonts/Oxygen/oxygen-bold-webfont.ttf') format('truetype'),
         url('fonts/Oxygen/oxygen-bold-webfont.svg#oxygenbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'oxygenlight';
  src: url('fonts/Oxygen/oxygen-light-webfont.eot');
  src: url('fonts/Oxygen/oxygen-light-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/Oxygen/oxygen-light-webfont.woff2') format('woff2'),
       url('fonts/Oxygen/oxygen-light-webfont.woff') format('woff'),
       url('fonts/Oxygen/oxygen-light-webfont.ttf') format('truetype'),
       url('fonts/Oxygen/oxygen-light-webfont.svg#oxygenlight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'oratorregular';
  src: url('fonts/Orator/orator-regular-webfont.eot');
  src: url('fonts/Orator/orator-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/Orator/orator-regular-webfont.woff2') format('woff2'),
       url('fonts/Orator/orator-regular-webfont.woff') format('woff'),
       url('fonts/Orator/orator-regular-webfont.ttf') format('truetype'),
       url('fonts/Orator/orator-regular-webfont.svg#oratorregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

$font-base: oxygenregular;
$font-bold: Oxygen-Bold;
$font-light: Oxygen-Light;

$font-primary: oratorregular;


$mqLarge: 1700px;
$mqDesktop: 1280px;
$mqTablet: 989px;
$mqPhone: 650px;
$mqTiny: 560px;
